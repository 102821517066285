/* Clockinclockout.css */

body {
    margin: 0;
    padding: 0;
    
}

.header-clockin {
    font-family: 'Roboto';
    padding: 5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    color: rgb(212, 212, 216);
    background-color: #0c2d49;
  
}

.header-clockin div {
    font-size: 24px;
    margin: 5px;
}

.clockinclockoutContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    justify-content: space-around;
    background-color: #f2f2f2; /* Added color */
}


 #clockin-button  {
    text-align: center;
    cursor: pointer;
    width: 30%;
    align-self:center;
    height: 15vh;
    border-radius: 10px;
    font-size: 24px;
    /* align-self: center; */
    background-color:#156519; /*#fcd7c5;*/
    border: none;
    /* color: rgb(54, 49, 49); */
    color: rgb(230, 230, 230);
    font-weight: 400;
    margin-left: 10%;
    margin-top : 2%;
}

#clockout-button {
    
    text-align: center;
    cursor: pointer;
    width: 30%;
    align-self:center;
    height: 15vh;
    border-radius: 10px;
    font-size: 24px;
    /* align-self: center; */
    background-color:#b46d37; /*#fcd7c5;*/
    border: none;
    /* color: rgb(54, 49, 49); */
    color: rgb(236, 236, 236);
    font-weight: 400;
    margin-left: 10%;
    margin-top : 2%;
   
}




/* @media screen and (max-width: 768px) {
    .header-clockin div {
        font-size: 16px;
        width: calc(50% - 20px);
    }
} */

@media screen and (max-width: 480px) {
    .header-clockin div {
        font-size: 16px;
         width: calc(100% - 20px);
    }
}


 .clockedintime , .clockedouttime {
    font-size: 24px; /* Adjust as needed */
    color: rgb(86, 83, 83); /* Adjust as needed */
    background-color: rgb(228, 228, 227); 
}




/* Clockinclockout.css */

/* ... other styles ... */

.totalTime {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: #4ece6e; /* Light grey color */
    border: 2px solid #43b14a; /* Light blue color */
    padding: 10px;
    font-size: 1.5em;
    text-align: center;
}

@media screen and (max-width: 768px) {
    .totalTime {
        font-size: 1.2em; /* Smaller font size for medium screens */
    }
}

@media screen and (max-width: 480px) {
    .totalTime {
        font-size: 0.5em; /* Even smaller font size for small screens */
    }
}



#clockintext{


font-size: 20px;

}

#clockouttext{

    background-color: rgb(234, 244, 252);
    font-size: 20px;
    
    }


      .clockinmap{
        flex : 1
    }

    .ClockinClockOuttextcontainer{
        flex : 1
    }

    .relocateButton  {
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 5px;
        margin-top: 5px;
        width: 100px;
        height: 30px;
        font-size: 16px;
        background-color: white;
        border-radius: 5px;
        border: 1px solid;
        color: rgb(4 30 73 / 70%);
    }

    .relocateButton:hover {    
        background-color: rgba(16, 67, 148, 0.192);
        border-radius: 5px;
        border: 1px solid;
        color: rgba(6, 40, 94, 0.7);
    }
    

    .clockin-confirmtext{
        margin-top: 10px;
        text-align: center;
        font-size: 24px;
        font-weight: bold;
        color: #1f5f97;
        margin-right: 100px;
    }
    


    @media screen and (max-width: 600px) {
        .clockin-confirmtext{ font-size: 16px;margin-right: 0px;}
        .clockinclockoutContainer{
            width: 100%;
        }

        .clockedintime  , .clockedouttime  {
            font-size: 16px; /* Adjust as needed */ 
        }

        #clockin-button , #clockout-button  {
            margin-top: 20%;
            width: 80%;}

            .relocateButton {
                font-size: 14px;
                width: 100px;
                height: 20px;
            }
    }



    .clockin-line {
        border-left: 2px solid black;
        height: 50px;
        margin: 0 10px;
        border-color:  rgb(179, 179, 179); 
    }
    