body {
    font-family: Arial, sans-serif;
    overflow-x: auto;
  }
  
  .headertile {
    text-align: center;
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
    padding: 10px;
    background-color: #0c2d49;
    color: white;
    width: 100%;
    font-family: Roboto ;
  }
  
  .tableContainer {
    max-height: 60%;
    overflow-y: auto;
    display: block;
 
  }
  
  .table_1 {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    
  }
  
  .table_1 th, .table_1 td {
    padding: 10px;
    text-align: center;
    border: 1px solid #ddd;
    width: 180px;
    
    box-sizing: border-box;
    overflow: auto;
  
  }
  
  
  
  .table_1 tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .table_1 tr:nth-child(odd) {
    background-color: #ffffff;
  }

  .table_1 tr:hover {
    background-color: #ddd;
  }
  

  .table_1parent th:first-child,.table_1parent td:first-child {
    position: sticky;
    left: 0;
    z-index: 1;
    background-color: inherit;
   
}

.table_1parent th:nth-child(2),.table_1parent td:nth-child(2) {
  position: sticky;
  left: 180px; /* Adjust this value to match the width of your first column */
  z-index: 1;
  background-color: inherit;
  
}


.table_1parent .table_1 th {
  background-color: #0c2d49;
  color: white;
  position: sticky;
  top: 0;
  left: 180;
  z-index: 2;
}




.table_1parent th:first-child, .table_1parent th:nth-child(2){
  z-index: 3;
}


 @media (max-width: 600px) {
  
  body.responsive {
    font-family: Arial, sans-serif;
    overflow: auto;
    margin: 0;
    padding: 0;
  }

  .headertile.responsive {
    text-align: center;
    font-size: 14px;
    padding: 20px;
    background-color: #0c2d49;
    color: white;
    width: 100%;
    top: 0;
    position: fixed;
  }

  .tableContainer.responsive {
    max-height: 600px;
    display: inline-block;
    padding-top: 10px;

  }

.select-container.responsive 

      {
       padding-top: 25vh;
      } 


      


  }




  /* Responsive table */
  /* @media (max-width: 600px) {
    .table_1, .table_1 tbody, .table_1 tr, .table_1 td {
      display: block;
    }
  
    .table_1 thead {
      display: none;
    }
  
    .table_1 tr { border: 1px solid #ccc; }
  
    .table_1 td {
      border: none;
      border-bottom: 1px solid #eee;
      position: relative;
      padding-left: 50%;
     
    }
  
    .table_1 td::before {
      position: absolute;
      top: 6px;
      left: 6px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
      content: attr(data-label);
    }
  } */
  
  .leavetable {
    width: 100%;
    border-collapse: collapse;
  }
  
  .leaverow:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .leavecell {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
    white-space: nowrap; /* Prevents the text from wrapping onto the next line */
  }

  .headerButton button {
    background-color: #ffffff00;;
    color: #fff;
    border: none;
    font-size: 16px;
    cursor: pointer;
    margin-bottom:none;
    border-radius: 5px;
    padding-top: 10px ;
    padding-bottom:10px;
    margin: 20px;
  }

  






  
  /* This will apply when the screen size is 600px or less */
  @media screen and (max-width: 600px) {
 
    .leavecell {
      font-size: 14px; /* Reduces the font size */
      padding: 2px; /* Reduces the padding */
    
    }

    .headerButton{   
      display: flex;
      justify-content: space-around;
      flex-direction: row;
   
    }

    .headerButton button{  
       margin: 2px;
    }

  }
  
 .leavetable th:nth-child(2),  .leavetable td:nth-child(2) {
    position: static;
    left: 180px; /* Adjust this value to match the width of your first column */
    z-index: 1;
    background-color: inherit;
    
}



  

  
  
  
  
 



  .headerButton button:hover {
    background-color: #1d619d;
  
  }
  
  /* styles.css */



/* Style the select container */
.select-container {
  display: flex;
  justify-content: space-between;
  width: 300px;
  margin: 20px auto;
  
}



/* Style the select elements */
.select-container select {
  width: 140px;
  padding: 5px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  transition: all 0.3s ease;
}

/* Style the select elements on hover */
.select-container select:hover {
  border-color: #888;
}

/* Style the select elements when they are focused */
.select-container select:focus {
  outline: none;
  border-color: #007BFF;
}






.downloadButton {
  padding: 10px;
  background-color: #113f67; /* Change as needed */
  color: white; /* Change as needed */
  border: none;
  cursor: pointer;
  font-size: 16px;
  position: sticky;
  top: 0;
  left: 10px;
  z-index: 3;
  border-radius: 5px; /* Optional: to make the button rounder */
}
.downloadButton:hover {
  background-color: #45a049; /* Change as needed */
}