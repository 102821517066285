.navigation-button{
    border: none;
    margin-right: 10px;
    margin-bottom: 5px;
    margin-top: 5px;
    width: 100px;
    height: 30px;
    font-size: 16px;
    background-color: white;
    border-radius: 5px;
    border: 1px solid;
    color: rgb(4 30 73 / 70%);
}


.navigation-button:hover{
    background-color: aliceblue;
}



@media screen and (max-width: 600px) {
    .navigation-button{
        font-size: 14px;
        width:  70px;
        height: 20px;
    }

}