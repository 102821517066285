/* Login.css */

.login-container , .signup-container {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 40px;
  border: 0px solid #ccc;
  border-radius: 0px;
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
 
}

.login-title , .signupTitle{
  text-align: center;
  margin-bottom: 40px;
  color: #605d5c;
}

.login-form , .SignUpForm {
  display: flex;
  flex-direction: column;
}

.login-form label,.SignUpForm label {
  margin-bottom: 5px;
}

.login-form input ,.SignUpForm input {
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 20px;
  font-size: 16px;
  box-sizing: border-box; /* Ensure input box size includes padding */
  width: 100%; /*Take up full width */
  height: 60px;
}

.login-form button , .SignUpForm button {
  padding: 10px;
  background-color: #12416bd2;
  color: rgb(255, 255, 255);
  border: none;
  border-radius: 20px;
  font-size: 24px;
  cursor: pointer;
  width: 100%;
  height: 60px;
  
}

.login-form button :hover, .SignUpForm button :hover {
  background-color: #1a5b93d2;
  
}


.login-form input:focus, .SignUpForm input:focus {
  outline: 2px solid #dcdbda;
}


@media screen and (max-width: 480px) {
  .login-container , .signup-container {
    width: 90%;
  }
}


.google-btn {
  background: none;
  border: 1px solid rgb(141, 139, 139);
  cursor: pointer;
  width: 100%;
  height: 60px;
  border-radius: 20px;
}

.custom-link{
  color: #117aca; /* Change this to your desired color */
  text-decoration: none;
  text-align: center;
}


.login-ui , .signup-container{
  display: flex ;
  flex-direction: column;
}


h4, h2, .custom-link{
  margin: 5px 0;
  padding: 0;
}

.signup-alreadytext{
  margin-top: 10px;
  text-align: center;
  color: #7a7878;
}