

.home-container {
    display: flex;
    flex-direction: column;
  }

 

  .home-headerArea{
    display: flex;
    margin-left: 0;
    flex-direction: row;
    margin-left: 10vw;

  }

  .home-header {
    font-size: 10vw;
    text-align: left;
    margin-top: 2%;
    color: rgb(74, 66, 66);
    flex: 1;
}

 .home-headerbutton
 { 
    border-radius: 1px;
    border: 0px;
    font-size: 2.5vw;
    margin-top: 10vh;
    color: rgb(74, 66, 66);
    transition: all 0.3s ease;
    margin-left: 11vw;
    flex: 1;
}

.home-headerbutton-container{     flex: 1;}

.brancolor{color : #12416bd2}

.home-bodyArea{
    display: flex;
    flex-direction: row;
    margin-left: 10vw;
    font-weight: bold;
    
  }


  .home-buttonArea{
    flex-direction: column;
    flex: 1;
  }

.home-button {
    width: 20vw;
    height: 15vh;
    border-radius: 20px;
    font-size: 2.5vw;
    /* align-self: center; */
    margin-left: 13vw;
    margin-bottom: 5vh;
    background-color:#12416bd2; /*#fcd7c5;*/
    border: 0px;
    /* color: rgb(54, 49, 49); */
    color: rgb(255, 255, 255);
    transition: all 0.3s ease; /* Smooth transition for all changes */
    font-weight: 400;
}

/* Hover effect */
.home-button:hover  {
    background-color: #1b588ed2  ;/* Change color on hover  #ddd;* /
    cursor: pointer; /* Change cursor to pointer on hover */
    transform: scale(1.1); /* Scale the button to 105% of its original size on hover */
}

.home-headerbutton:hover{
  transform: scale(1.1);
}

/* Active or click effect */
.home-button:active {
    background-color: #154c7b; /* Change color on click */
}

.home p {
  
    text-align: left;
    font-size: 5vw;
    color: rgb(74, 66, 66);
    flex: 1;
 
  
}

@media (max-width: 600px) {
  .home-header {
    font-size: 15vw;}
    .home-button {
       
      width: 80vw;
      height: 15vh;
      font-size: 8vw;
      margin: 10% auto 2% auto; /* top and bottom margins are 10vh and 2vh, left and right margins are auto */
      display: block; /* this will override the flex display from .home-bodyArea */
    }
  
    .home-bodyArea {
        flex-direction: column;
        margin-left:0;
      }

    .home p {
        width: 80vw;
        margin: 10% auto 2% auto;
        font-size: 24px;
        color: rgb(74, 66, 66);
        flex: 1;  
        text-align: center;
      
    }

    .home-headerbutton
    {  
       border-radius: 1px;
       border: 0px;
       font-size: 5vw;
       margin-top: 40%;
       color: rgb(74, 66, 66);
       transition: all 0.3s ease;
       margin-left: 10%;
       flex: 1;
      
   }

  }
  
  
