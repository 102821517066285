.logout-button{
    width: 100%;
    border: none;
    font-size: 14px;
    /* align-self: center; */
    background-color:#0f365600; /*#fcd7c5;*/
    border: 0px;
    /* color: rgb(54, 49, 49); */
    color: rgb(201, 199, 199);
    transition: all 0.3s ease; /* Smooth transition for all changes */
    font-weight: 400;
}


@media screen and (max-width: 480px) {

    .logout-button{
        width: 30%;
        font-size: 14px;
    }

}