/* LeaveManagment.css */

.Lms {
  text-align: center;
}

.Lms-header {
  background-color: #0c2d49;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-size: 24px;
  padding: 10px;

}

.react-calendar {
  margin: 0 auto; /* Center the calendar on the page */
  width: 400px; /* Set a specific width */
  border: 1px solid #0c2d49; /* Add a border */
  font-family: Arial, sans-serif; /* Change the font */
}

.leave-reason {
  width: 20%;
  height: 40px;
  padding: 10px;
  font-size: 16px;
  margin-bottom: 10px;
}

.apply-button {
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  border: none;
  background-color: blue;
  color: white;
  margin-top: 10px;
}

.confirmation-text {
  font-weight: bold;
  font-size: 1.2em;
  margin-top: 20px;
}
.apply-button:active {
  background-color: lightblue; /* Change this to any lighter color you prefer */
}

table.empleave-table {

  border-collapse: collapse;
}
.empleave-table th.empleave-th, .empleave-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}
.empleave-table tr.empleave-row:nth-child(even) {
  background-color: #f2f2f2;
}
.empleave-table th.empleave-th {
  background-color: #0c2d49;
  color: white;
}

.leavenavigation-button{
  border: none;
  margin-right: 10px;
  margin-bottom: 5px;
  margin-top: 5px;
  width: 120px;
  height: 30px;
  font-size: 16px;
  background-color: #134169e0;;
  border-radius: 5px;
  border: 1px solid;
  color: rgba(255, 255, 255, 0.813);
}


.leavenavigation-button:hover{
  background-color: #2065a1e0;
}

table.empleave-table {
  margin-left: auto;
  margin-right: auto;
  width: 50%; /* You can adjust this value to suit your needs */
}




  @media screen and (max-width: 600px) {
    .Lms-header{height:10vh}
    .leavenavigation-button{
      font-size: 14px;
      height: 20px;
  }
  table.empleave-table {
    width: 80%; /* You can adjust this value to suit your needs */
  }
  }