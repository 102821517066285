.contact-header {
    font-size: 10vw;
    text-align: left;
    margin: 2%;
    color: rgb(74, 66, 66);
    flex: 1;
}


.contact-button{

        border-radius: 5px;
        border: 0px;
        font-size: 2.5vw;
        color: rgb(250, 250, 250);
        transition: all 0.3s ease;
        margin: 10%;
        padding: 10px;
        background-color: #12416bd2;
        
    
}

.contact-button:hover{
    transform: scale(1.1);
  }


  .contact-buttondiv
  {
    flex: 1;
  }