/* css file for ManageEmp table */

.managemp-table {
    width: 100%;
    margin-top: 10vh;
    table-layout: fixed;
    border-collapse: collapse;
    border: 1px solid rgba(22, 21, 21, 0.801);
}


.managemp-table th {
 text-align: center;
 border: 1px solid rgba(22, 21, 21, 0.801);
 background-color: #0c2d49;
  color : white;
}

.managemp-table td{
    text-align: center;
    border: 1px solid rgba(22, 21, 21, 0.801);
    word-wrap: break-word;
   }
   

.manageemp h1 {
    text-align: center;
 
}

.manageemp .acceptbutton{
    background-color: rgb(85, 184, 85);
    border-radius: 4px;
}

.manageemp .rejectbutton{
    background-color: rgb(206, 69, 69);
    border-radius: 4px;
}


.manageemp-header{

       font-family: Roboto;
        color : white;
        padding: 20px; 
        background-color: #0c2d49;
        margin: none;
        font-size: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
      
}

.manageemp-infotext {
    font-family: Roboto;
    background-color: #dcdbda; 
    color: #000; 
    padding: 20px; 
    border-radius: 1px; 
    margin: 10px;
    line-height: 1.5; /* Adjust this value as needed */
  }
  

  .navigateAttendance{
    
        padding: 10px;
        background-color: #113f67; /* Change as needed */
        color: white; /* Change as needed */
        border: none;
        cursor: pointer;
        font-size: 16px;
        border-radius: 5px; /* Optional: to make the button rounder */
        margin: 10px;
  }

  .navigateAttendance:hover{
    background-color: #1a5a91;
  }

  .manage-headerButton{
    background-color: #0c2d49;
    display: flex;
    justify-content: center;
     align-items: center ;
  }

  .manageemp-parentheader{background-color: #0c2d49;}