body {
    background-color: #f0f0f0; /* Light grey background */
  }
  
 
  
  .employee-dashboard {
    width: 100%;
    height: 100vh;
    margin: none;
    background-color: #f0f0f0; /* White background */
    display: flex;
    flex-direction: column;
    color: white;

    
  }
  
  .search-input,
  .search-button {
    width: 30%;
    height: 50px; /* Same height for textbox and buttons */
    padding: 10px;
    font-size: 16px;
    margin-top: 10px;
    border-radius: 5px; /* Slightly rounded corners */
    border: 1px solid;
  }


  .join-button {
    width: 10%;
    height: 50px; /* Same height for textbox and buttons */
    padding: 10px;
    font-size: 16px;
    margin-top: 10px; /* Remove border */
    border-radius: 5px; /* Slightly rounded corners */
    margin-right: 10px;
    margin-bottom: 10px; 
    background-color: rgba(17, 171, 17, 0.74);
  }

  .join-button:active{
    background-color:rgb(18, 230, 18);
  }

  
  .search-button
   {
    background-color: #0c2d49; /* Blue background */
    color: #ffffff; /* White text */
    cursor: pointer; /* Cursor changes when you hover over it */
  }
  
  .search-button:hover
  {
    background-color: #134672; /* Darker blue when you hover over it */
  }
  
  .org-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px;
    width: 60%;
  }
  
  .no-org-message {
    margin-top: 20px;
    color: #ff0000; /* Red text */
  }
  
  .org-info:nth-child(even) {
    background-color: #f2f2f2; /* Light grey for even rows */
  }
  
  .org-info:nth-child(odd) {
    background-color: #ffffff; /* White for odd rows */
  }
  
  .searchorg-header{
    text-align: center;
    border-bottom: #d2d0d0 solid 1px;
    background-color:#0c2d49 ;
    padding: 10px;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }


  .requestedorg{
    background-color: rgba(27, 209, 27, 0.587);
  }


.searchedorgname{
  margin: 10px;
  color: #0c2d49;
}

  @media screen and (max-width: 600px) {

    .searchorg-header{
      width:100%;
      height:10vh;
    }

     .employee-dashboard{
      align-items: center;
     }

    .search-input,
    .search-button {
      width: 80%;
      height: 10vh; /* Same height for textbox and buttons */
      padding: 10px;
      font-size: 16px;
      margin-top: 30px;
      border-radius: 5px; /* Slightly rounded corners */
    }

    .join-button {
      width: 30%;
      height: 10vh; /* Same height for textbox and buttons */
      padding: 10px;
      font-size: 16px;
      margin-top: 10px; /* Remove border */
      border-radius: 5px; /* Slightly rounded corners */
      margin-right: 10px;
      margin-bottom: 10px; 
      background-color: rgba(17, 171, 17, 0.74);
    }

    .org-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 50px;
      width: 100%;
    }

  }