.selection-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f0f0; /* Change this to your preferred color */
}

.selection-button, .modal button {
  margin: 10px;
  padding: 20px;
  font-size: 4vh;
  width: 30vh;
  background-color: #144876;
  color: #f0f0f0;
  border: none;
  
  /* Add more styles here to make the buttons look similar */
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.modal {
  background-color: rgba(255, 255, 255); /* Slightly opaque white */
}

.selection-button:hover, .modal button:hover{
  background-color: #205d93d2 ;
}

/* styles.css */

.select-inputField {
  width:  30vh; /* Sets the width */
  padding: 12px 20px; /* Adds some padding */
  margin: 8px 0; /* Adds some margin to the top and bottom */
  box-sizing: border-box; /* Ensures padding and border are included in the total width and height */
  border: 2px solid rgb(9, 9, 9); /* Adds a border */
  border-radius: 4px; /* Rounds the corners */
  background-color: #f7f7f7; /* Sets a background color */
  color: rgb(0, 0, 0); /* Sets the text color */
}

